export const SYNTAX_ERROR = 'SYNTAX_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR'

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR'
export const REFERENCE_ERROR = 'REFERENCE_ERROR'
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR'
export const PG_UNIQUE_ERROR = '23505'
export const REQUEST_APPROVAL_CONTRACT_TYPE_MISS_MATCH =
  'REQUEST_APPROVAL_CONTRACT_TYPE_MISS_MATCH'
export const FILE_TYPE_ERROR = 'FILE_TYPE_ERROR'

export const FILE_DOWNLOAD_ERROR = 'FILE_DOWNLOAD_ERROR'
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR'
export const REQUEST_APPROVAL_CONFIRM_ORDER_ERROR =
  'REQUEST_APPROVAL_CONFIRM_ORDER_ERROR'
export const REQUEST_APPROVAL_NOT_READY_ERROR =
  'REQUEST_APPROVAL_NOT_READY_ERROR'
export const REQUEST_APPROVAL_NOT_NEW_ERROR = 'REQUEST_APPROVAL_NOT_NEW_ERROR'
export const COMPANY_UPDATE_CONTRACT_ERROR = 'COMPANY_UPDATE_CONTRACT_ERROR'
export const CONTRACT_TEMPLATE_NOT_FOUND_ERROR =
  'CONTRACT_TEMPLATE_NOT_FOUND_ERROR'
export const CONTRACT_TEMPLATE_TYPE_MISS_MATCH =
  'CONTRACT_TEMPLATE_TYPE_MISS_MATCH'

import {QueryErrorResetBoundary, useSuspenseQuery} from '@tanstack/react-query'
import {compact} from 'lodash'
import type {SnackbarKey} from 'notistack'
import {closeSnackbar, enqueueSnackbar} from 'notistack'
import {Suspense, useEffect, useMemo} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {createRoutesFromElements, Navigate, Route} from 'react-router'
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from 'react-router-dom'
import AppShell from './components/containers/AppShell'
import ErrorFallback from './components/screens/ErrorFallback'
import SplashScreen from './components/screens/SplashScreen'
import Login from './components/screens/login/Login'
import {ApiProvider, useOnline} from './hooks/api'
import {AuthProvider, useSession} from './hooks/auth'
import {AlertsProvider} from './hooks/useAlert'
import {MenuProvider, useMenu} from './hooks/useMenu'
import usePermissions from './hooks/usePermissions'
import ThemeProvider from './theme/ThemeProvider'

const Shell = () => {
  const {openMenu, setOpenMenu} = useMenu()
  const menuClick = () => {
    setOpenMenu((prevOpen: boolean) => !prevOpen)
  }

  return (
    <AppShell onMenuClick={menuClick} openMenu={openMenu}>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </AppShell>
  )
}

const ErrorElement = () => {
  const error = useRouteError()
  throw error
}

const Screens = () => {
  const session = useSession()
  const permissions = usePermissions()
  const isOnline = useOnline()

  const {data: routes} = useSuspenseQuery({
    queryKey: ['getRoutes'],
    queryFn: async () => {
      const routeImports = await Promise.all([import('./components/screens')])
      return compact(routeImports.map((routeImport) => routeImport?.default))
    },
  })

  useEffect(() => {
    let snackbarKey: SnackbarKey | null = null
    if (!isOnline) {
      snackbarKey = enqueueSnackbar(
        'Nemáte připojení k internetu nebo server je nedostupný',
        {
          variant: 'error',
          persist: true,
        },
      )
    }
    return () => {
      if (snackbarKey) {
        closeSnackbar(snackbarKey)
        snackbarKey = null
      }
    }
  }, [isOnline])

  const router = useMemo(() => {
    if (session && permissions) {
      return createBrowserRouter([
        {
          id: 'root',
          element: <Shell />,
          errorElement: <ErrorElement />,
          children: createRoutesFromElements(
            routes?.map((getRoutes) => getRoutes(permissions)),
          ),
        },
      ])
    }

    return createBrowserRouter([
      {
        id: 'root',
        errorElement: <ErrorElement />,
        children: createRoutesFromElements(
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>,
        ),
      },
    ])
    // We don't use session?.token inside useMemo, but we still want
    // to rerender the router when the token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.user.id])

  return <RouterProvider router={router} />
}

const App = () => (
  <QueryErrorResetBoundary>
    {({reset}) => (
      <ErrorBoundary
        onReset={reset}
        fallbackRender={({resetErrorBoundary}) => (
          <ErrorFallback resetError={resetErrorBoundary} />
        )}
      >
        <ThemeProvider>
          <AlertsProvider>
            <AuthProvider>
              <ApiProvider>
                <MenuProvider>
                  <Screens />
                </MenuProvider>
              </ApiProvider>
            </AuthProvider>
          </AlertsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
)

export default App

import * as approvalContractTypes from 'constants/enums/approvalContractTypes'
import * as documentationContractTypes from 'constants/enums/documentationContractTypes'

export default {
  [documentationContractTypes.RESERVATION_AGREEMENT]: 'Rezervační smlouva',
  [documentationContractTypes.CONTRACT_FOR_A_FUTURE_PURCHASE_AGREEMENT]:
    'Smlouva o smlouvě budoucí kupní',
  [documentationContractTypes.PURCHASE_AGREEMENT]: 'Kupní smlouva',
  [approvalContractTypes.ON_SET_OFF_AGREEMENT]: 'Dohoda o zápočtu',
  [approvalContractTypes.ASSIGNMENT_OF_RECEIVABLES_AGREEMENT]:
    'Dohoda o postoupení pohledávky',
  [approvalContractTypes.AGREEMENT_CONTRACT_ASSIGNMENT_PROVISION_SURCHARGE]:
    'Dohoda o postoupení smlouvy - poskytnutí příplatku',
  [approvalContractTypes.AGREEMENT_CONTRACT_ASSIGNMENT_RETURN_SURCHARGE]:
    'Dohoda o postoupení smlouvy - vrácení příplatku',
  [approvalContractTypes.FRAMEWORK_LOAN_AGREEMENT]: 'Rámcová smlouva o úvěru',
  [approvalContractTypes.ADDENDUM_TO_THE_FRAMEWORK_LOAN_AGREEMENT]:
    'Dodatek k rámcové smlouvě o úvěru',
  [approvalContractTypes.DECISION_OF_THE_SOLE_SHAREHOLDER_ON_APPROVAL]:
    'Rozhodnutí jediného společníka/akcionáře o schválení účetní závěrky',
  [approvalContractTypes.OTHERS]: 'Ostatní',
} as const

import ErrorIcon from '@mui/icons-material/Error'
import type {FC} from 'react'
import {ErrorScreen} from '../visual/'

type ErrorFallbackProps = {
  resetError: () => void
}

const ErrorFallback: FC<ErrorFallbackProps> = ({resetError}) => {
  const handleReset = () => {
    resetError()
  }

  return (
    <ErrorScreen
      Icon={ErrorIcon}
      title="Neočekávaná chyba"
      buttonLabel="Resetovat aplikaci"
      onClick={handleReset}
    >
      <div>Nastala neočekávaná chyba. Informujte, prosím, správce systému.</div>
    </ErrorScreen>
  )
}

export default ErrorFallback

export const ON_SET_OFF_AGREEMENT = 'on_set_off_agreement'
export const ASSIGNMENT_OF_RECEIVABLES_AGREEMENT =
  'assignment_of_receivables_agreement'
export const AGREEMENT_CONTRACT_ASSIGNMENT_PROVISION_SURCHARGE =
  'agreement_contract_assignment_provision_surcharge'
export const AGREEMENT_CONTRACT_ASSIGNMENT_RETURN_SURCHARGE =
  'agreement_contract_assignment_return_surcharge'
export const FRAMEWORK_LOAN_AGREEMENT = 'framework_loan_agreement'
export const ADDENDUM_TO_THE_FRAMEWORK_LOAN_AGREEMENT =
  'addendum_to_the_framework_loan_agreement'
export const DECISION_OF_THE_SOLE_SHAREHOLDER_ON_APPROVAL =
  'decision_of_the_sole_shareholder_on_approval'
export const OTHERS = 'others'

export const ALL_APPROVAL_CONTRACT_TYPES = [
  ON_SET_OFF_AGREEMENT,
  ASSIGNMENT_OF_RECEIVABLES_AGREEMENT,
  AGREEMENT_CONTRACT_ASSIGNMENT_PROVISION_SURCHARGE,
  AGREEMENT_CONTRACT_ASSIGNMENT_RETURN_SURCHARGE,
  FRAMEWORK_LOAN_AGREEMENT,
  ADDENDUM_TO_THE_FRAMEWORK_LOAN_AGREEMENT,
  DECISION_OF_THE_SOLE_SHAREHOLDER_ON_APPROVAL,
  OTHERS,
] as const

export type ApprovalContractType = (typeof ALL_APPROVAL_CONTRACT_TYPES)[number]

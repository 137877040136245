import {TextField as MuiTextField} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC} from 'react'

type TextInputProps = ComponentProps<typeof MuiTextField> & {
  wide?: boolean
}

const StyledTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== 'wide',
})<TextInputProps>(({theme, wide}) => ({
  ...(wide && {
    width: wide && '400px',

    [theme.breakpoints.down('sm')]: {
      width: wide && '225px',
    },
  }),
}))

const TextInput: FC<TextInputProps> = ({
  label,
  multiline,
  wide,
  error,
  helperText,
  required,
  fullWidth,
  disabled,
  ...props
}) => {
  return (
    <StyledTextField
      label={label}
      multiline={multiline}
      error={error}
      helperText={helperText}
      required={required}
      fullWidth={fullWidth}
      disabled={disabled}
      wide={wide}
      {...props}
    />
  )
}

export default TextInput

import {styled} from '@mui/material/styles'
import type {FC} from 'react'

const StyledAppDivider = styled('div')({
  flexGrow: 1,
})

type AppBarDividerProps = Record<string, never>

const AppBarDivider: FC<AppBarDividerProps> = () => {
  return <StyledAppDivider />
}

export default AppBarDivider

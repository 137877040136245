export const RESERVATION_AGREEMENT = 'reservation_agreement'
export const CONTRACT_FOR_A_FUTURE_PURCHASE_AGREEMENT =
  'contract_for_a_future_purchase_agreement'
export const PURCHASE_AGREEMENT = 'purchase_agreement'

export const ALL_DOCUMENTATION_CONTRACT_TYPES = [
  RESERVATION_AGREEMENT,
  CONTRACT_FOR_A_FUTURE_PURCHASE_AGREEMENT,
  PURCHASE_AGREEMENT,
] as const

export type DocumentationContractType =
  (typeof ALL_DOCUMENTATION_CONTRACT_TYPES)[number]

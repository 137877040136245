import invariant from 'invariant'
import {isObject, join} from 'lodash-es'
import type {ZodErrorMap} from 'zod'
import {ZodIssueCode, ZodParsedType} from 'zod'

const plural = (
  count: number | bigint,
  zero: string,
  one: string,
  few: string,
  many: string,
) => {
  if (count === 0) return zero
  if (count === 1) return one
  if (count < 5) return few
  return many
}

const types = {
  function: 'funkce',
  number: 'číslo',
  string: 'text',
  nan: 'není číslo',
  integer: 'celé číslo',
  float: 'desetinné číslo',
  boolean: 'pravdivostní hodnota',
  date: 'datum',
  bigint: 'velké číslo',
  undefined: 'nedefinováno',
  symbol: 'symbol',
  null: 'neexistující hodnota',
  array: 'pole',
  object: 'objekt',
  unknown: 'neznámá hodnota',
  promise: 'příslib',
  void: 'prázdný',
  never: 'nikdy',
  map: 'zobrazení',
  set: 'množina',
}

// eslint-disable-next-line complexity
const zodErrorMap: ZodErrorMap = (issue, ctx) => {
  let message: string
  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      if (issue.received === ZodParsedType.undefined) {
        message = 'Povinné'
      } else {
        message = `Neplatná hodnota. Zadejte ${types[issue.expected]}`
      }
      break
    case ZodIssueCode.invalid_literal:
      message = `Neplatná hodnota. Povolené hodnoty: ${JSON.stringify(issue.expected)}`
      break
    case ZodIssueCode.unrecognized_keys:
      message = `Nerozpoznané klíče v objektu: ${join(issue.keys, ', ')}`
      break
    case ZodIssueCode.invalid_union:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_union_discriminator:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_enum_value:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_arguments:
      message = 'Neplatné argumenty funkce'
      break
    case ZodIssueCode.invalid_return_type:
      message = 'Neplatný typ výstupu funkce'
      break
    case ZodIssueCode.invalid_date:
      message = 'Neplatné datum'
      break
    case ZodIssueCode.invalid_string:
      if (isObject(issue.validation)) {
        if ('startsWith' in issue.validation) {
          message = `Neplatná hodnota: musí začínat "${issue.validation.startsWith}"`
        } else if ('endsWith' in issue.validation) {
          message = `Neplatná hodnota: musí končit "${issue.validation.endsWith}"`
        } else {
          invariant(false, 'Neplatná hodnota')
        }
      } else if (issue.validation !== 'regex') {
        message = `Neplatný ${issue.validation}`
      } else {
        message = 'Neplatná hodnota'
      }
      break
    case ZodIssueCode.too_small:
      if (issue.type === 'array') {
        message = `Pole musí obsahovat ${
          issue.exact ? 'přesně' : issue.inclusive ? 'alespoň' : 'více než'
        } ${issue.minimum} prvek(ů)`
      } else if (issue.type === 'string') {
        message = `Text musí obsahovat ${
          issue.exact ? 'přesně' : issue.inclusive ? 'alespoň' : 'více než'
        } ${issue.minimum} ${plural(issue.minimum, 'znaků', 'znak', 'znaky', 'znaků')}`
      } else if (issue.type === 'number') {
        message = `Číslo musí být ${
          issue.exact
            ? 'přesně '
            : issue.inclusive
              ? 'větší nebo rovno '
              : 'větší než '
        }${issue.minimum}`
      } else if (issue.type === 'date') {
        message = `Datum musí být ${
          issue.exact
            ? 'přesně '
            : issue.inclusive
              ? 'větší nebo rovno '
              : 'větší než '
        }${new Date(Number(issue.minimum))}`
      } else message = 'Neplatná hodnota'
      break
    case ZodIssueCode.too_big:
      if (issue.type === 'array') {
        message = `Pole musí obsahovat ${
          issue.exact ? 'přesně' : issue.inclusive ? 'nejvýše' : 'méně než'
        } ${issue.maximum} ${plural(issue.maximum, 'prvků', 'prvek', 'prvky', 'prvků')}`
      } else if (issue.type === 'string') {
        message = `Text musí obsahovat ${
          issue.exact ? 'přesně' : issue.inclusive ? 'nejvýše' : 'méně než'
        } ${issue.maximum} ${plural(issue.maximum, 'znaků', 'znak', 'znaky', 'znaků')}`
      } else if (issue.type === 'number') {
        message = `Číslo musí být ${
          issue.exact
            ? 'přesně '
            : issue.inclusive
              ? 'menší nebo rovno'
              : 'menší než'
        } ${issue.maximum}`
      } else if (issue.type === 'date') {
        message = `Datum musí být ${
          issue.exact
            ? 'přesně '
            : issue.inclusive
              ? 'menší nebo rovno'
              : 'menší než'
        } ${new Date(Number(issue.maximum))}`
      } else message = 'Neplatná hodnota'
      break
    case ZodIssueCode.custom:
      message = 'Neplatná hodnota'
      break
    case ZodIssueCode.invalid_intersection_types:
      message = 'Hodnota nesplňuje všechny podmínky'
      break
    case ZodIssueCode.not_multiple_of:
      message = `Číslo musí být násobek ${issue.multipleOf}`
      break
    case ZodIssueCode.not_finite:
      message = 'Číslo musí být konečné'
      break
    default:
      message = ctx.defaultError
  }
  return {message}
}

export default zodErrorMap

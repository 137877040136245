import type {PaletteOptions} from '@mui/material'

export default {
  common: {
    black: 'black',
    white: 'white',
  },
  primary: {
    light: '#C53348',
    main: '#AA1E32',
    dark: '#AA1E32',
    contrastText: '#FFF',
  },
  secondary: {
    light: '#CECDCD',
    main: '#231F20',
    dark: '#231F20',
    contrastText: '#231F20',
  },
  success: {
    light: '#cff1e5',
    main: '#10B981',
    dark: '#0b815a',
    contrastText: '#10B981',
  },
  info: {
    dark: '#0288D1',
    main: '#29B6F6',
    light: '#E1F5FE',
    contrastText: '#FFF',
  },
  warning: {
    main: '#F79009',
    light: '#FFF3E0',
    contrastText: '#FFF',
  },
  error: {
    light: '#FDF0F0',
    main: '#DC0D15',
    contrastText: '#FFF',
  },
  neutral: {
    main: '#686868',
  },
  text: {
    primary: '#3B3B3B',
    secondary: '#A8A8A8',
  },
  background: {
    default: '#F9F9F9',
    paper: '#FFF',
  },
  grey: {
    300: '#F3F4F5',
    400: '#D2D2D2',
    600: '#686868',
  },
  tonalOffset: 0.4,
  divider: '#CAC4D0',
} satisfies PaletteOptions

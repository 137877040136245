import type {RemoveIndex} from 'common/types'
import type {ValidationErrors} from 'final-form'
import arrayMutators from 'final-form-arrays'
import {identity} from 'lodash'
import {isFunction} from 'lodash-es'
import type {ComponentType, FC, ReactElement, ReactNode} from 'react'
import {useMemo} from 'react'
import getDisplayName from 'react-display-name'
import type {FormProps, FormRenderProps} from 'react-final-form'
import {Form} from 'react-final-form'
import type {PartialDeep} from 'type-fest'
import type {TypeOf, ZodType} from 'zod'
import {validator} from './forms'

declare module 'react-final-form' {
  interface FormRenderProps {
    submitError?: unknown
  }
}

export type PartialValues<TValues> = PartialDeep<
  TValues,
  {recurseIntoArrays: true}
>

type Config<
  TSchema extends ZodType<Record<string, unknown>>,
  TInitialValues extends PartialValues<TypeOf<TSchema>>,
> = {
  schema: TSchema
  parse?: (values: TypeOf<TSchema>) => TypeOf<TSchema>
  format?: (values: TInitialValues) => TInitialValues
  validate?: (
    values: unknown,
  ) =>
    | {values: TypeOf<TSchema>; errors: undefined}
    | {values: undefined; errors: ValidationErrors}
}

type WrappedFormProps<
  TValues extends Record<string, unknown>,
  TProps extends Record<string, unknown>,
> = RemoveIndex<
  Omit<FormRenderProps<TValues, PartialValues<TValues>>, 'form'>
> &
  TProps

type FinalProps<
  TSchema extends ZodType<Record<string, unknown>>,
  TInitialValues extends PartialValues<TypeOf<TSchema>>,
  TRestProps extends Record<string, unknown>,
> = {
  children?: (
    props: WrappedFormProps<TypeOf<TSchema>, TRestProps> & {form: ReactNode},
  ) => ReactElement
  initialValues?: TInitialValues
  innerProps?: TRestProps
} & Omit<RemoveIndex<FormProps<TypeOf<TSchema>, TInitialValues>>, 'children'>

type ComponentProps = {
  handleSubmit: FormRenderProps['handleSubmit']
  children: ReactNode
}

const Component: FC<ComponentProps> = ({handleSubmit, children}) => {
  return useMemo(
    () => <form onSubmit={handleSubmit}>{children}</form>,
    [children, handleSubmit],
  )
}

const withForm =
  <
    TSchema extends ZodType<Record<string, unknown>>,
    TInitialValues extends PartialValues<TypeOf<TSchema>>,
  >({
    schema,
    parse: providedParse,
    format: providedFormat,
    validate: providedValidate,
  }: Config<TSchema, TInitialValues>) =>
  <TProps extends Record<string, unknown>>(
    WrappedComponent: ComponentType<WrappedFormProps<TypeOf<TSchema>, TProps>>,
  ) => {
    const validate = providedValidate || validator(schema)
    const parse = providedParse || identity
    const format = providedFormat || identity
    const Final = ({
      onSubmit,
      children,
      initialValues,
      innerProps = {} as TProps,
      ...rest
    }: FinalProps<TSchema, TInitialValues, TProps>) => {
      return (
        <Form<TypeOf<TSchema>, TInitialValues>
          mutators={{
            ...arrayMutators,
          }}
          keepDirtyOnReinitialize
          onSubmit={
            // Because onSubmit will only ever be called after successful validation

            (values, ...args) =>
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onSubmit(validate(parse(values)).values!, ...args)
          }
          validate={(values) => validate(parse(values)).errors}
          initialValues={initialValues ? format(initialValues) : undefined}
          {...rest}
        >
          {({handleSubmit, initialValues, ...formProps}) => {
            const form = (
              <Component handleSubmit={handleSubmit}>
                <WrappedComponent
                  handleSubmit={handleSubmit}
                  initialValues={initialValues}
                  {...formProps}
                  {...innerProps}
                />
              </Component>
            )

            if (isFunction(children)) {
              return children({
                handleSubmit,
                initialValues,
                ...innerProps,
                ...formProps,
                form,
              })
            }
            return form
          }}
        </Form>
      )
    }

    Final.displayName = `FinalForm(${getDisplayName(WrappedComponent)})`
    return Final
  }

export default withForm

import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  LinearProgress,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import type {Theme} from '@mui/material/styles'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC, ReactNode} from 'react'
import AppBarDivider from './AppBarDivider'

const StyledMuiAppBar = styled(MuiAppBar)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    '& .MuiToolbar-root': {
      padding: theme.spacing(0, 1),
    },
  },
}))

const StyledIconButton = styled(IconButton)(({theme}) => ({
  margin: '-8px',
  padding: 0,
  marginRight: theme.spacing(4),
  color: theme.palette.common.white,

  [theme.breakpoints.down('sm')]: {
    margin: '0',
    marginRight: theme.spacing(2),
  },
}))

const StyledAppBarItems = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',

  '& button': {
    color: theme.palette.common.white,
  },

  '& p': {
    padding: '0 0.5rem',
  },
}))

type AppBarProps = Omit<ComponentProps<typeof MuiAppBar>, 'title'> & {
  isFetching?: boolean
  onMenuClick?: (...args: unknown[]) => void
  brand?: ReactNode
  title?: ReactNode
  children?: ReactNode
}

type AppBarType = FC<AppBarProps> & {Divider: typeof AppBarDivider}

const AppBar: AppBarType = ({
  isFetching,
  onMenuClick,
  brand,
  title,
  children,
  ...props
}) => {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <StyledMuiAppBar {...props}>
      {isFetching ? <LinearProgress /> : null}
      <Toolbar>
        <StyledIconButton onClick={onMenuClick}>
          <MenuIcon />
        </StyledIconButton>
        {brand ? brand : null}
        <StyledAppBarItems>
          {title && !isSm ? <Typography>{title}</Typography> : null}
          {children}
        </StyledAppBarItems>
      </Toolbar>
    </StyledMuiAppBar>
  )
}

AppBar.Divider = AppBarDivider

export default AppBar

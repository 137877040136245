import enums from 'common/labels/enums'
import type {ListUserResponseSchema} from 'common/responses'
import type {ValidationErrors} from 'final-form'
import {ARRAY_ERROR} from 'final-form'
import {get, isArray, set} from 'lodash-es'
import type {ReactNode} from 'react'
import type {FieldMetaState} from 'react-final-form'
import type {ZodIssue, ZodType, TypeOf} from 'zod'
import {z, ZodParsedType, ZodIssueCode} from 'zod'
import zodErrorMap from './zodErrorMap'

export type ShowError = (args: {
  meta: FieldMetaState<unknown>
  helperText: ReactNode
}) => {
  isError: boolean
  helperTextOrError: ReactNode
}

export const showErrorOnChange: ShowError = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched, modified},
  helperText,
}) => {
  const isError = Boolean(
    ((submitError && !dirtySinceLastSubmit) || error) && (touched || modified),
  )
  const helperTextOrError = isError
    ? ((error || submitError) as ReactNode)
    : helperText

  return {isError, helperTextOrError}
}

export const showErrorOnBlur: ShowError = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched},
  helperText,
}) => {
  const isError = Boolean(
    ((submitError && !dirtySinceLastSubmit) || error) && touched,
  )
  const helperTextOrError = isError
    ? ((error || submitError) as ReactNode)
    : helperText

  return {isError, helperTextOrError}
}

z.setErrorMap(zodErrorMap)

const parseErrorSchema = (zodErrors: ZodIssue[]) => {
  const errors: ValidationErrors = {}
  for (; zodErrors.length; ) {
    const [error] = zodErrors

    if (
      !get(errors, error.path) &&
      !(
        error.code === ZodIssueCode.invalid_type &&
        error.expected === ZodParsedType.undefined
      )
    ) {
      if (
        error.code === ZodIssueCode.invalid_type &&
        error.expected === ZodParsedType.array
      ) {
        // Empty array must be kept as array for final form to work
        if (!isArray(get(errors, error.path))) set(errors, error.path, [])
        error.path.push(ARRAY_ERROR)
      }

      if ('unionErrors' in error) {
        const [unionError] = error.unionErrors[0].errors
        set(errors, error.path, unionError.message)
      } else {
        set(errors, error.path, error.message)
      }
    }

    if ('unionErrors' in error) {
      error.unionErrors.forEach((unionError) =>
        unionError.errors.forEach((e) => zodErrors.push(e)),
      )
    }

    zodErrors.shift()
  }

  return errors
}

export const validator =
  <TSchema extends ZodType<Record<string, unknown>>>(
    schema: TSchema,
    opts = {},
  ) =>
  (
    passedValues: unknown,
  ):
    | {errors: undefined; values: TypeOf<TSchema>}
    | {errors: ValidationErrors; values: undefined} => {
    const res = schema.safeParse(passedValues, opts)

    if (res.success) {
      return {errors: undefined, values: res.data}
    }

    const parsedErrors = parseErrorSchema(res.error.issues)
    return {errors: parsedErrors, values: undefined}
  }

type User = ListUserResponseSchema[number]

export const getUserOptionText = (option: {
  name: User['name']
  role: User['role']
}) => {
  return option.name + (option?.role ? `, ${enums.roles[option.role]}` : '')
}

import {memoize} from 'lodash-es'
import * as pathToRegexp from 'path-to-regexp'

export type Params = Record<string, string>

const compile = memoize(pathToRegexp.compile)
const generatePath = (path: string, params: Params = {}) =>
  compile(path)(params)

export default generatePath

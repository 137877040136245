import {Button} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, FC, ReactNode} from 'react'

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100%',
  paddingTop: '10%',
}))

const StyledIcon = styled('div')(() => ({
  fontSize: 200,
}))

const StyledButton = styled('button')(() => ({
  marginTop: 25,
}))

type ErrorScreenProps = {
  Icon: ComponentProps<typeof StyledIcon>['as']
  title: string
  buttonLabel: ReactNode
  onClick: () => void
  children: ReactNode
}

const ErrorScreen: FC<ErrorScreenProps> = ({
  Icon,
  title,
  buttonLabel,
  onClick,
  children,
}) => {
  return (
    <StyledDiv>
      <StyledIcon as={Icon} />
      <h1>{title}</h1>
      {children}
      <StyledButton as={Button} onClick={onClick}>
        {buttonLabel}
      </StyledButton>
    </StyledDiv>
  )
}

export default ErrorScreen

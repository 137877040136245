import invariant from 'invariant'
import type {Dispatch, FC, ReactNode, SetStateAction} from 'react'
import {createContext, useContext, useState} from 'react'

type MenuContextType = {
  openMenu: boolean
  setOpenMenu: Dispatch<SetStateAction<boolean>>
}

const MenuContext = createContext<MenuContextType | null>(null)

type MenuProviderProps = {
  children: ReactNode
}

export const MenuProvider: FC<MenuProviderProps> = ({children}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  return (
    <MenuContext.Provider value={{openMenu, setOpenMenu}}>
      {children}
    </MenuContext.Provider>
  )
}

export const useMenu = () => {
  const menu = useContext(MenuContext)
  invariant(menu, 'useMenu must be used within a MenuProvider')
  return menu
}

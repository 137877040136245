import {styled} from '@mui/material/styles'
import type {FC, ReactNode} from 'react'
import logo from '../../../assets/logo.svg'

const StyledBackgroundImage = styled('div')(({theme}) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
}))

const StyledContentWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '7rem 0 1.5rem',
  minHeight: '100vh',
  backdropFilter: 'blur(5px)',
}))

const StyledContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '100%',
  textAlign: 'center',
}))

const StyledCard = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '19.75rem',
  margin: 'auto',
  zIndex: '1',
  padding: '0.5rem 1.5rem 1.5rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.palette.common.white,

  '& > :not(:first-child)': {
    marginTop: '1rem',
  },

  '& > form': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',

    '& > :nth-child(3)': {
      textAlign: 'start',
    },

    '& > button': {
      width: '100%',
    },

    '& > span': {
      display: 'inline-block',
      marginTop: '0.5rem',
      color: theme.palette.error.main,
    },
  },
}))

const StyledLogo = styled('img')(() => ({
  margin: 'auto',
  maxWidth: '11rem',
  paddingTop: '2.125rem',
}))

const StyledNote = styled('div')(() => ({
  paddingTop: '1rem',
}))

type LoginProps = {
  note: ReactNode
  footer: ReactNode
  children: ReactNode
}

const Login: FC<LoginProps> = ({note, footer, children, ...props}) => {
  return (
    <StyledBackgroundImage {...props}>
      <StyledContentWrapper>
        <StyledContent>
          <StyledCard>
            <StyledLogo src={logo} alt="Logo" />
            {children}
          </StyledCard>
          <StyledNote>{note}</StyledNote>
        </StyledContent>
        {footer}
      </StyledContentWrapper>
    </StyledBackgroundImage>
  )
}

export default Login

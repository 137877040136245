export const NEW = 'new'
export const PENDING_SIGNATURE = 'pending_signature'
export const CLOSED = 'closed'
export const CANCELLED = 'cancelled'

export const ALL_REQUEST_APPROVAL_STATUSES = [
  NEW,
  PENDING_SIGNATURE,
  CLOSED,
  CANCELLED,
] as const

export type RequestApprovalStatus =
  (typeof ALL_REQUEST_APPROVAL_STATUSES)[number]

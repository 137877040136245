import {Button, Stack} from '@mui/material'
import * as schemas from 'common/schemas'
import {isString} from 'lodash-es'
import type {z} from 'zod'
import TextInput from '../../form/TextInput'
import {showErrorOnBlur} from '../../form/forms'
import withForm from '../../form/withForm'

export const schema = schemas.object({
  email: schemas.string().email(),
})

export type LoginSchema = z.infer<typeof schema>

const LoginForm = withForm({schema})(({submitting, submitError}) => (
  <>
    <Stack useFlexGap gap={2}>
      <TextInput
        name="email"
        label="E-mailová adresa"
        required
        showError={showErrorOnBlur}
      />
      <Button variant="contained" type="submit" disabled={submitting}>
        Přihlásit
      </Button>
      {isString(submitError) ? <span>{submitError as string}</span> : null}
    </Stack>
  </>
))

export default LoginForm

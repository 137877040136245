export const USERS = 'users'
export const CONTRACT_TEMPLATES = 'contractTemplates'
export const REQUEST_APPROVALS = 'requestApprovals'
export const REQUEST_APPROVAL_COMMENTS = 'requestApprovalComments'
export const REQUEST_APPROVAL_CONTRACTS = 'requestApprovalContracts'
export const REQUEST_APPROVAL_REVISIONS = 'requestApprovalRevisions'
export const REQUEST_APPROVAL_CONTRACT_FILES = 'requestApprovalContractFiles'
export const FILES = 'files'

export const ALL_RESOURCES = [
  USERS,
  CONTRACT_TEMPLATES,
  REQUEST_APPROVALS,
  REQUEST_APPROVAL_COMMENTS,
  REQUEST_APPROVAL_REVISIONS,
  REQUEST_APPROVAL_CONTRACT_FILES,
  FILES,
] as const

export type Resource = (typeof ALL_RESOURCES)[number]

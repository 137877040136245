import type {ComponentProps, FC} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {Link as VisualLink} from '../visual'

type LinkProps = Omit<ComponentProps<typeof VisualLink>, 'component'> &
  Omit<ComponentProps<typeof RouterLink>, 'ref'>

const Link: FC<LinkProps> = ({children, to, ...props}) => {
  return (
    <VisualLink component={RouterLink} to={to} {...props}>
      {children}
    </VisualLink>
  )
}

export default Link

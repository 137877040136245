import allType from './allType'
import approvalContractTypes from './approvalContractTypes'
import contractStatuses from './contractStatuses'
import documentationContractTypes from './documentationContractTypes'
import processes from './processes'
import requestApprovalStatuses from './requestApprovalStatuses'
import roles from './roles'
import signingMethods from './signingMethods'

export const ALL_TYPES = 'allTypes'
export const APPROVAL_CONTRACT_TYPES = 'approvalContractTypes'
export const CONTRACT_STATUSES = 'contractStatuses'
export const DOCUMENTATION_CONTRACT_TYPES = 'documentationContractTypes'
export const PROCESSES = 'processes'
export const REQUEST_APPROVAL_STATUSES = 'requestApprovalStatuses'
export const ROLES = 'roles'
export const SIGNING_METHODS = 'signingMethods'

const enums = {
  [ALL_TYPES]: allType,
  [APPROVAL_CONTRACT_TYPES]: approvalContractTypes,
  [CONTRACT_STATUSES]: contractStatuses,
  [DOCUMENTATION_CONTRACT_TYPES]: documentationContractTypes,
  [PROCESSES]: processes,
  [REQUEST_APPROVAL_STATUSES]: requestApprovalStatuses,
  [ROLES]: roles,
  [SIGNING_METHODS]: signingMethods,
} as const

export type Enums = typeof enums
export type Enum = keyof Enums

export default enums

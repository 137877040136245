import {EventStreamContentType} from '@microsoft/fetch-event-source'
import {onlineManager, useQueryClient} from '@tanstack/react-query'
import type {ErrorResponse} from 'common/responses'
import * as schemas from 'common/schemas'
import {API_UPDATES} from 'constants/routes'
import {useMemo, useRef} from 'react'
import type {SSEOptions} from '../utils/api'
import {FrontendError} from '../utils/api'
import {invalidateResource, useSSE} from './api'

class RetriableError extends Error {}

const useResourceMutationSubscription = () => {
  const queryClient = useQueryClient()
  const retryCount = useRef(0)
  const options = useMemo<SSEOptions>(
    () => ({
      openWhenHidden: true,
      onopen: async (res) => {
        if (
          res.ok &&
          res.headers.get('content-type') === EventStreamContentType
        ) {
          // Reset retry count and online status
          retryCount.current = 0
          onlineManager.setOnline(true)

          // There might have been updates while the SSE connection was being established
          await queryClient.invalidateQueries({
            type: 'all',
            refetchType: 'active',
          })
          return
        }

        if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          const errorData = (await res.json()) as ErrorResponse
          const error = new FrontendError(
            (errorData && errorData.message) ||
              res.statusText ||
              'Unknown error',
          )
          error.data = errorData
          throw error
        }
        throw new RetriableError()
      },
      onmessage: async (event) => {
        if (!event.data) return // Skip heartbeats

        const result = schemas.common
          .resource()
          .safeParse(JSON.parse(event.data))
        if (!result.success) return
        await invalidateResource(result.data, queryClient)
      },
      onerror: (error) => {
        // TypeError sometimes represents a network error
        if (!(error instanceof RetriableError || error instanceof TypeError))
          throw error
        onlineManager.setOnline(false)
        retryCount.current += 1
        // Backoff with a max of 10 seconds
        return Math.max(2 ** retryCount.current * 1000, 10_000)
      },
      onclose: () => {
        throw new RetriableError()
      },
    }),
    [queryClient],
  )
  useSSE(API_UPDATES, options)
}

export default useResourceMutationSubscription

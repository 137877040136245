export const NEW = 'new'
export const PENDING_SIGNATURE = 'pending_signature'
export const REALIZED = 'realized'
export const NOT_REALIZED = 'not_realized'

export const ALL_CONTRACT_STATUSES = [
  NEW,
  PENDING_SIGNATURE,
  REALIZED,
  NOT_REALIZED,
] as const

export type ContractStatus = (typeof ALL_CONTRACT_STATUSES)[number]

import {FORM_ERROR} from 'final-form'
import {isError} from 'lodash'
import type {ComponentProps} from 'react'
import {useNavigate} from 'react-router'
import {useAuth} from '../../../hooks/auth'
import {Footer, Login as VisualLogin} from '../../visual'
import LoginForm from './LoginForm'

const Login = () => {
  const navigate = useNavigate()
  const {login} = useAuth()
  const handleLogin: ComponentProps<typeof LoginForm>['onSubmit'] = async (
    values,
  ) => {
    const res = await login(values)

    if (isError(res)) {
      return {[FORM_ERROR]: 'Špatný email'}
    }

    if (res.user.id) {
      navigate('/', {replace: true})
    }
  }

  return (
    <VisualLogin
      note=""
      footer={<Footer color="info.main" from={1994} version="1.0.0" />}
    >
      <LoginForm onSubmit={handleLogin} />
    </VisualLogin>
  )
}

export default Login

import {useQueryErrorResetBoundary} from '@tanstack/react-query'
import type {GetMeResponseSchema} from 'common/responses'
import {AUTHENTICATION_ERROR} from 'constants/errorCodes'
import * as routes from 'constants/routes'
import invariant from 'invariant'
import {noop} from 'lodash'
import type {FC, ReactNode} from 'react'
import {
  Component,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import SplashScreen from '../components/screens/SplashScreen'
import type {LoginSchema} from '../components/screens/login/LoginForm'
import {api} from '../utils/api'
import type {FrontendError} from './api'
import {useAlert} from './useAlert'
import useIsMounted from './useIsMounted'

type Session = GetMeResponseSchema

type AuthContextContext = {
  refreshAuth: () => Promise<void>
  login: (data: LoginSchema) => Promise<Session | Error>
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContextContext | null>(null)
const SessionContext = createContext<Session | null>(null)

type ErrorBoundaryProps = {
  onError: () => void
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  componentDidCatch(error: FrontendError) {
    if (error?.data?.errorCode === AUTHENTICATION_ERROR) {
      this.props.onError()
      return
    }
    throw error
  }

  render() {
    return this.props.children
  }
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({children}) => {
  const [isInitialized, setInitialized] = useState(false)
  const [session, setSession] = useState<Session | null>(null)
  const showAlert = useAlert()
  const isMounted = useIsMounted()
  const {reset} = useQueryErrorResetBoundary()

  const handleError = () => {
    showAlert('Byl jste odhlášen', 'error')
    if (isMounted.current) setSession(null)
    reset()
  }

  const login: AuthContextContext['login'] = useCallback(
    async (data) => {
      let newSession: Session | null
      try {
        try {
          await api('POST', '/auth/signin/email', {
            data,
          })
        } catch (error) {
          console.error(error)
        }
        try {
          await api('GET', '/auth/callback/email', {
            query: {
              callbackUrl: 'http://localhost:3001/api/me',
              token: String(new Date().getTime()),
              email: data.email,
            },
          })
        } catch (error) {
          console.error(error)
        }
        newSession = (await api<GetMeResponseSchema>('GET', routes.API_ME)).data
        if (isMounted.current) setSession(newSession)
        return newSession
      } catch (e: unknown) {
        newSession = null
        if (isMounted.current) setSession(newSession)
        return e as Error
      }
    },
    [isMounted],
  )

  const logout: AuthContextContext['logout'] = useCallback(async () => {
    try {
      await api('POST', '/auth/signout')
    } catch (e: unknown) {
      console.error(e)
    } finally {
      if (isMounted.current) setSession(null)
    }
  }, [isMounted])

  const refreshAuth: AuthContextContext['refreshAuth'] =
    useCallback(async () => {
      let newSession: Session | null = null
      try {
        newSession = (await api<Session>('GET', routes.API_ME)).data
      } catch (_e) {
        newSession = null
      }

      if (isMounted.current) setSession(newSession)
      if (isMounted.current) setInitialized(true)
    }, [isMounted])

  useEffect(() => {
    refreshAuth().catch(noop)
  }, [refreshAuth])

  const utils = useMemo(
    () => ({
      refreshAuth,
      login,
      logout,
    }),
    [refreshAuth, login, logout],
  )

  if (!isInitialized) return <SplashScreen />

  return (
    <>
      <AuthContext.Provider value={utils}>
        <SessionContext.Provider value={session}>
          <ErrorBoundary onError={handleError}>{children}</ErrorBoundary>
        </SessionContext.Provider>
      </AuthContext.Provider>
    </>
  )
}

export const useAuth = () => {
  const authUtils = useContext(AuthContext)
  invariant(authUtils, 'useAuth must be used within AuthProvider')
  return authUtils
}

export const useSession = () => {
  const session = useContext(SessionContext)
  return session
}

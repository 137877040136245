import type {VariantType} from 'notistack'
import {SnackbarProvider, useSnackbar} from 'notistack'
import type {FC, ReactNode} from 'react'

type AlertsProviderProps = {
  children: ReactNode
}

export const AlertsProvider: FC<AlertsProviderProps> = ({children}) => (
  <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
    {children}
  </SnackbarProvider>
)

export const useAlert = () => {
  const {enqueueSnackbar} = useSnackbar()

  return (message: string, variant: VariantType = 'default') => {
    return enqueueSnackbar(message, {
      variant,
    })
  }
}

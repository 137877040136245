import {Link, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {format} from 'date-fns'
import type {FC} from 'react'

const StyledContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(3),
}))

const StyledMuiTypography = styled(Typography)(({theme}) => ({
  display: 'block',
  marginLeft: theme.spacing(1),
}))

type FooterProps = {
  color?: string
  version: string
  from: number
  changelog?: string
}

const Footer: FC<FooterProps> = ({
  color = 'text.secondary',
  version,
  from,
  changelog,
  ...props
}) => {
  return (
    <StyledContainer>
      <StyledMuiTypography color={color} variant="caption" {...props}>
        © {from}-{format(new Date(), 'Y')} SATPO&nbsp;
        <Link
          component="a"
          href={changelog}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography color={color} variant="caption">
            {version}
          </Typography>
        </Link>
        &nbsp; Vytvořil Ernst & Young, s.r.o.
      </StyledMuiTypography>
    </StyledContainer>
  )
}

export default Footer

/* eslint-disable no-process-env */
import {createEnv} from '@t3-oss/env-core'
import {z} from 'zod'

const boolean = () => z.string().transform((value) => value === 'true')

export const runtimeEnvStrict = {
  publicDevel: process.env.public_devel,
  publicTest: process.env.public_test,
  publicServerUrl: process.env.public_server_url,
  publicHost: process.env.public_host,
  publicPort: process.env.public_port,
  privateMaxLimit: process.env.private_max_limit,
  publicMaxLimit: process.env.public_max_limit,
  publicMaxFileSize: process.env.public_max_file_size,
  publicMaxFileCount: process.env.public_max_file_count,
  privateServeSwagger: process.env.private_serve_swagger,
  privateDbConnection: process.env.private_db_connection,
  privateDbDebug: process.env.private_db_debug,
  privateHost: process.env.private_host,
  privatePort: process.env.private_port,
  privateCsvDelimiter: process.env.private_csv_delimiter,
  privateAuthSecret: process.env.private_auth_secret,
  privateAuthTrustHost: process.env.private_auth_trust_host,
  privateS3EndpointUrl: process.env.private_s3_endpoint_url,
  privateS3AccessKeyId: process.env.private_s3_access_key_id,
  privateS3SecretAccessKey: process.env.private_s3_secret_access_key,
  privateS3Bucket: process.env.private_s3_bucket,
  privateRedisUrl: process.env.private_redis_url,
  privateLogLevel: process.env.private_log_level,
  privateLogAudit: process.env.private_log_audit,
  privateRunServer: process.env.private_run_server,
  privateRunWorker: process.env.private_run_worker,
  privateAresUrl: process.env.private_ares_url,
  privateAresMaxConcurrency: process.env.private_ares_max_concurrency,
  privateAresRequestTimeout: process.env.private_ares_request_timeout,
}

export const config = {
  clientPrefix: 'public',
  isServer: typeof window === 'undefined' && process.title !== 'webpack', // We want to run webpack server only with client env
  server: {
    privateServeSwagger: boolean().optional(),
    privateDbConnection: z.string().min(1),
    privateDbDebug: boolean().optional(),
    privateHost: z.string().min(1),
    privatePort: z.coerce.number().min(1),
    privateMaxLimit: z.coerce.number().optional().default(10),
    privateCsvDelimiter: z.string().optional(),
    privateAuthSecret: z.string(),
    privateAuthTrustHost: boolean().optional(),
    /**
     * Connection string for Redis, leave empty to disable
     */
    privateRedisUrl: z.string().url().optional(),
    /**
     * Log level for server logger
     */
    privateLogLevel: z
      .enum(['trace', 'debug', 'info', 'warn', 'error', 'fatal'])
      .optional()
      .default('info'),
    /**
     * Enable server audit logging - setting this to true will log every request
     */
    privateLogAudit: boolean().optional(),
    privateS3EndpointUrl: z.string().min(1),
    privateS3AccessKeyId: z.string().min(1),
    privateS3SecretAccessKey: z.string().min(1),
    privateS3Bucket: z.string().min(1),
    /**
     * Enables HTTP server. Used mainly to split server and worker in production
     */
    privateRunServer: boolean().optional().default('true'),
    /**
     * Enables BullMQ worker. Used mainly to split server and worker in production
     */
    privateRunWorker: boolean().optional().default('true'),
    privateAresUrl: z.string().min(1),
    privateAresMaxConcurrency: z.coerce.number().optional().default(10),
    privateAresRequestTimeout: z.coerce.number().optional().default(10000),
  },
  client: {
    publicDevel: boolean().optional(),
    publicTest: boolean().optional(),
    publicHost: z.string().min(1),
    publicPort: z.coerce.number().min(1),
    publicServerUrl: z.string().min(1),
    /**
     * Maximum `pageSize` for list queries
     */
    publicMaxLimit: z.coerce.number().optional().default(200),
    publicMaxFileSize: z.coerce.number().default(5000000),
    publicMaxFileCount: z.coerce.number().default(100),
  },
  runtimeEnvStrict,
  emptyStringAsUndefined: true,
} as const

export const env = createEnv(config)

import * as resources from 'constants/resources'
import type {Resource} from 'constants/resources'

const invalidationMap = {
  [resources.USERS]: [resources.REQUEST_APPROVALS],
  [resources.CONTRACT_TEMPLATES]: [resources.REQUEST_APPROVALS],
  [resources.REQUEST_APPROVALS]: [
    resources.REQUEST_APPROVAL_REVISIONS,
    resources.REQUEST_APPROVAL_CONTRACT_FILES,
  ],
  [resources.REQUEST_APPROVAL_COMMENTS]: [],
  [resources.REQUEST_APPROVAL_REVISIONS]: [],
  [resources.FILES]: [
    resources.REQUEST_APPROVALS,
    resources.REQUEST_APPROVAL_CONTRACT_FILES,
  ],
  [resources.REQUEST_APPROVAL_CONTRACT_FILES]: [],
} as const satisfies {[r in Resource]: Resource[]}

export default invalidationMap
